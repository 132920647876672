:root {
  --tobii-base-font-size: 1rem; /* also update --tobii-slide-max-height */

  --tobii-transition-duration: 0.3s;
  --tobii-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  --tobii-zoom-icon-background: hsla(210, 38%, 16%, 0.94);
  --tobii-zoom-icon-color: #ffffff;

  --tobii-lightbox-background: rgba(0,0,0,0.85);
  --tobii-lightbox-z-index: 1337;

  --tobii-caption-background: rgba(0,0,0,0.8);
  --tobii-caption-color: #eeeeee;

  --tobii-counter-background: transparent;
  --tobii-counter-color: #ffffff;

  --tobii-button-background: transparent;
  --tobii-button-navigation-background: rgba(0,0,0,0.5);
  --tobii-button-color: #ffffff;

  --tobii-loader-color: #ffffff;

  --tobii-slide-max-height: calc(100vh - 3.125em);
  --tobii-slide-max-width: 100vw;
}
