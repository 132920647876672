// Base
body {
    background-color: #a0bcba;
}

main > .container {
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
    padding-bottom: 1rem;
}

.page-header {
    padding-top: 3rem;
    padding-bottom: 10px;
    margin: 44px 0 22px;
    border-bottom: 1px solid #eee;
}

/* Navbar */
.nav-link:after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 1px;
	background: $white;
	content: '';
	opacity: 0;
	transition: height 0.3s, opacity 0.3s, transform 0.3s;
	transform: translateY(-10px);
}
.nav-link:hover:after {
	height: 3px;
	opacity: 1;
	transform: translateY(0px);
}
  
.active-nav-item {
    box-shadow: $white 0 3px;
}

.nav-item {
    // fixes hover effekt for bootstrap
    position: relative;
}

// SVG Icon Styles
.icon {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: currentColor;

    &.icon--en,
    &.icon--de {
        width: 27px;
        height: 18px;
    }

    .carousel-caption &.icon--sun,
    .carousel-caption &.icon--check-circle,
    .carousel-caption &.icon--award,
    .carousel-caption &.icon--smile {
        width: 30px;
        height: 30px;
        filter: drop-shadow(3px 5px 2px rgba(0,0,0,.5));
    }
}

// Gallery
.gallery {
    display: grid;
    grid-gap: 2px;
    
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(5, 1fr);
    }
}

// Footer
.site-footer {
    border-top: 1px solid $gray-200;
    margin-top: 2rem;
    padding: 1rem 0;
    background-color: $white;
}
  
.footer-item {
    margin: 0.5rem;
    white-space: nowrap;
}

// Carousel
#carousel img {
    height: auto;
}

.carousel-caption h3 {
    text-shadow: 1px  1px 2px black,
                 1px -1px 2px black,
                -1px  1px 2px black,
                -1px -1px 2px black;
}
